.InputContainer {
    width: 100%;
}

.DateInputContainer{
    width: auto;
    display: flex;
    margin: auto;
}

label {
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
}

input {
    outline: none;
    color: #332d2d;
    width: 100%;
    height: 5px;
    background-color: transparent;
    outline: none;
    font-size: 18px;
    z-index: 1;
    padding: 16px;
    border: 0px;
}

input :focus {
    border-radius: 10px;
}

.react-transform-wrapper {
    width: 100% !important;
    /* height: 100%!important; */
}

.powered-by-container {
    display: none !important;
}

.tooltip {
    position: relative;
    display: inline-block;
    padding: 9px 0;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: #0f52ba;
    text-align: center;
    border-radius: 6px;
    padding: 15px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.fade-out {
    animation: fade-out 2s;
    background-color: #1d1d1d;
    border-radius: 16px;
    padding: 10px 15px;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}