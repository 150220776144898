.HeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 8px;
  backdrop-filter: blur(10px);
  position: relative;
}

.HeaderLine {
  width: 80%;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  height: 1px;
  margin-top: 10px;
  background-color: transparent;
  background-image: radial-gradient(circle, #579250 0, transparent 100%);
}

.BalanceSection {
  display: flex;
  align-items: center;
  border-radius: 13px;
  width: 90%;
  margin: 5px;
}

.BalancePart {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contractAddress {
  box-sizing: border-box;
  width: 12%;
  height: 40px;
}

.DateText {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  padding-right: 20px;
}

.ContractContainer {
  color: white;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 30px;
  border: 4px solid;
  border-color: #3b273c;
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    radial-gradient(
      circle farthest-corner at 66% 16%,
      rgba(2, 245, 255, 1) 33%,
      rgba(255, 94, 247, 1) 79%
    );
  background-size: auto;
  background-position: 0% 0%;
  background-repeat: repeat;
}

.PresaleContainer {
  background-image: linear-gradient(
    rgba(33, 33, 33, 0.05),
    rgba(66, 99, 99, 0.75)
  );
  padding: 0px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 4px rgba(88, 255, 255, 0.1);
}

.PresaleProgressContainer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.85));
  padding: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Text1 {
  color: white;
  font-size: 14px;
}

.Text2 {
  color: #c80b0b;
}

.PresaleButton {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
  linear-gradient(
    90deg,
    rgb(174, 37, 174) -3.12%,
    rgb(238, 138, 247) 29.71%,
    rgb(210, 71, 203) 73.6%,
    rgb(215, 103, 237) 102.14%
  );
  backdrop-filter: blur(38.5864px) !important;
  border-color: transparent;
  border-radius: 10px;
  border-width: 2px;
  color: white;
  text-shadow: 1px 1px 4px black;
  /* font-family: Public Sans; */
  font-size: 18px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition-duration: 0.3s;
  width: -webkit-fill-available;
  cursor: pointer;
  margin-top: 10px;
  font-weight: 600;
  transform: scale(1, 0.85);
  letter-spacing: 2px;
}

.PresaleButton:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
  linear-gradient(
    90deg,
    rgb(199, 56, 199) -3.12%,
    rgb(237, 151, 245) 29.71%,
    rgb(237, 93, 229) 73.6%,
    rgb(229, 119, 250) 102.14%
  );
  backdrop-filter: blur(38.5864px) !important;
  text-decoration: none;
  color: white;
}

.PresaleButton:active {
  background-color: transparent;
  color: white;
  transform: scale(1);
  cursor: pointer;
}

.PresaleButton:disabled {
  background: #c4b7c5;
  color: white;
  cursor: unset;
  margin: 20px;
}

.LockBox {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.MainDashboard {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.MaxButton {
  text-align: center;
  display: flex;
  align-items: center;
  display: flex;
  outline: none;
  border-image-slice: 1;
  font-size: 14px;
  text-align: center;
  align-items: center;
  font-weight: 100;
  color: #fff;
  transition: 0.1s ease-in-out;
  height: initial;
  cursor: pointer;
  margin: 0px;
}

.ConnectWalletBox {
  text-align: center;
  height: -webkit-fill-available;
  margin: auto;
  /* margin-bottom: 200px; */
  margin-top: 10%;
  margin-bottom: 20%;
}

.ConnectWalletBoxButton {
  display: flex;
  justify-content: space-evenly;
}

.SpinnerBox {
  display: flex;
  justify-content: space-evenly;
}

.ConnectWalletAgain {
  width: 100%;
  text-align: -webkit-center;
  height: 30%;
  margin-bottom: 30%;
}

.ButtonContainer {
  text-align: center;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ConnectButton {
  width: 25%;
  outline: none;
  border: 0px solid transparent;
  border-image-slice: 1;
  align-items: center;
  font-size: 17px;
  margin-left: -60px;
  margin-bottom: -15px;
  font-weight: 500;
  color: black;
  transition: 0.1s ease-in-out;
  height: 90%;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(
      90deg,
      rgb(174, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(210, 71, 203) 73.6%,
      rgb(215, 103, 237) 102.14%
    ) !important;
  backdrop-filter: blur(38.5864px) !important;
  padding: 10px 30px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  text-shadow: 1px 1px 4px black;
  display: block;
  font-weight: 600;
  transform: scale(1, 1);
}

.navConnectButton {
  width: 184px;
  outline: none;
  border: 0px solid transparent;
  border-image-slice: 1;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: black;
  transition: 0.1s ease-in-out;
  height: 90%;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(
      90deg,
      rgb(174, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(210, 71, 203) 73.6%,
      rgb(215, 103, 237) 102.14%
    ) !important;
  backdrop-filter: blur(38.5864px) !important;
  padding: 10px 10px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  text-shadow: 1px 1px 4px black;
  display: block;
  font-weight: 600;
  transform: scale(1, 1);
}

.ConnectButton:active {
  background-color: transparent;
  color: #26c5eb;
  transform: scale(1);
  cursor: pointer;
}

.ConnectButton:hover {
  background-position: right center;
  background-color: transparent;
  border: 0px solid #04a58a54;
  color: white;
  text-decoration: none;
}

.ConnectButton:disabled {
  background-color: #a6a6a6;
  box-shadow: none;
  cursor: no-drop;
}

.gradient-text {
  background: linear-gradient(90deg, rgb(109, 216, 212) 10%, rgb(2 206 107) 60%)
    text transparent;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
}

.GlobalContainer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    radial-gradient(
      circle farthest-corner at 10% 10%,
      rgba(255, 94, 247, 1) 17%,
      rgba(2, 245, 255, 1) 100%
    );
  background-size: auto;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  /* background: radial-gradient(100% 100% at 50% -20%, rgba(55, 43, 182, .2) 0, hsla(0, 0%, 100%, 0) 100%); */
  background-size: cover;
  color: white;
  width: auto;
  /* padding-top: 30px; */
  position: relative;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding-bottom: 30px;
}

.inputPanel {
  display: flex;
  /* align-items: center; */
  border: 1px solid #2b3553;
  border-radius: 15px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.inputPanel > p {
  text-wrap: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}

.InputSection {
  width: 45%;
}

.inputPanelHeader {
  display: flex;
  width: -webkit-fill-available;
  border: 2px solid transparent;
  border-radius: 10px;
  align-items: center;
  background: white;
}

.InputBox {
  width: 90%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.DashboardContainer {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  /* height: 50px; */
}

.LpBalance {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ContractContentTextTitle {
  font-size: 32px;
  color: white;
  text-align: center;
  transform: scale(1, 0.6);
  font-weight: 900;
}

.accountDetail {
  display: flex;
}

.accountData {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  flex-direction: column;
}

.withdrawContent {
  width: -webkit-fill-available;
  display: flex;
}

.LoadingBox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FirstNote {
  color: #1a449d;
  font-size: 25px;
}

.HeaderText {
  font-size: 20px;
  text-align: initial;
}

.HeaderContent {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  color: rgb(196, 196, 196);
  font-size: 14px;
  flex-direction: row;
}

.HeaderContent > p {
  margin: 0px;
}

.HeaderContent2 {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  color: white;
  font-size: 20px;
  flex-direction: row;
}

.HeaderContent2 > p {
  margin: 5px 0px 5px 0px;
}

.HeaderContent3 {
  width: -webkit-fill-available;
  height: 15px;
  border: 1px solid transparent;
  border-radius: 12px !important;
  background: white;
}

.HeaderContent4 {
  background: linear-gradient(
    90deg,
    rgb(174, 134, 37) -3.12%,
    rgb(247, 239, 138) 29.71%,
    rgb(210, 172, 71) 73.6%,
    rgb(237, 201, 103) 102.14%
  ) !important;
  height: 15px;
  border-radius: 12px;
}

.HeaderContent5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgb(196, 196, 196);
}

.HeaderContent6 {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
}
.HeaderContent7 {
  font-size: 14px;
}
.stageBox {
  border-color: rgb(4, 165, 138);
  border-radius: 8px;
  border-width: 1px;
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 10px;
}

.starting > p {
  margin: 0px;
  font-weight: 600;
}

.unstarted > p {
  margin: 0px;
  font-weight: 500;
}

.starting {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(
      0deg,
      rgb(163, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(199, 71, 210) 73.6%,
      rgb(226, 103, 237) 102.14%
    ) !important;
  backdrop-filter: blur(38.5864px) !important;
  color: white;
  border: 1px solid white;
  text-shadow: 0px 0px 4px black;
}

.unstarted {
  background: transparent;
  color: white;
  border: 1px solid white;
}

.one_how {
  position: relative;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.one_how::before {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 25%;
  background: white;
  left: 0;
}

.one_how::after {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 25%;
  background: white;
  right: 0;
}

.tokensButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.tokenImage {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.tokenHeader {
  height: 100%;
}

.tokenButtons {
  display: flex;
  padding: 3px 15px 3px 15px;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  border: 0.5px solid white;
  cursor: pointer;
  width: 30%;
  height: 50px;
  justify-content: center;
  font-size: 18px;
}

.HeaderList {
  text-align: initial;
}

.selected {
  border: 0.5px solid white;
  background: rgb(50, 35, 62);
}

.HeaderOfferText {
  text-align: initial;
  font-size: 17px;
}

.HeaderList > li {
  padding-bottom: 5px;
}

.LpDataContainer {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: white; */
  border-radius: 10px;
  border: 1px solid rgba(9, 62, 82, 0.2);
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border: 0.1px solid #555555;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  margin-bottom: 10px;
}

.ButtonContainer {
  display: flex;
  width: 100%;
}

.connectButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navConnectButtonBox {
  display: flex;
  justify-content: center;
}

.ChainGroupButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
@media screen and (max-width: 1600px) {
  .withdrawContent {
    flex-direction: column;
  }

  .ContractContainer {
    max-width: 60vw;
  }
}

@media screen and (max-width: 1050px) {
  .withdrawContent {
    flex-direction: column;
  }

  .ContractContainer {
    width: 80%;
    max-width: 100vw;
  }
}

@media screen and (max-width: 640px) {
  .GlobalContainer {
    padding: 0px;
  }

  .HeaderContainer {
    flex-direction: column;
  }

  .footer {
    flex-direction: column;
  }

  .BalanceSection {
    width: auto;
  }

  .tokenButtonText {
    font-size: 16px;
  }

  .navWallet {
    display: none;
  }

  .navConnectButton {
    width: 120px;
  }
}

@media screen and (max-width: 420px) {
  .tokenButtonText {
    font-size: 12px;
  }

  .ChainGroupButton{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: min-content;
    justify-content: space-around;
  }
}

@media screen and (max-width: 480px) {
  .navLink {
    display: none;
  }
}

@media screen and (max-width: 370px) {
  .tokenButtonText {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .tokensButton {
    width: 100%;
  }

  .ConnectButton{
    width: 140px;
    margin-bottom: 10px;
  }

  .HeaderContent6 {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }

  .InputSection {
    width: 100%;
  }

  .contractAddress {
    width: auto;
  }

  .inputPanel {
    flex-direction: row;
    width: 100%;
  }

  .inputPanelHeader {
    flex-direction: row;
    width: 100%;
  }

  .inputPanelHeaderContainer {
    flex-direction: row;
  }

  .MaxButton {
    padding: 14px;
  }

  .ContractContainer {
    width: 100%;
  }

  .DashboardContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.ResponsiveFlexLayout {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media (max-width: 1280px) {
  .ResponsiveFlexLayout {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ExchangeForm {
    padding-top: 0px;
  }

  .ContractContainer {
    padding: 10px;
  }
}

.LeftColumn {
  width: 50%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .LeftColumn {
    width: 100%;
    order: 0;
    max-width: 90%;
  }
}

.RightColumn {
  width: 50%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .RightColumn {
    width: 90%;
  }
}

.h1 {
  font-size: 30px;
  background: cyan;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25)),
    linear-gradient(
      90deg,
      rgb(192, 255, 255) -3.12%,
      rgb(138, 247, 238) 29.71%,
      rgb(127, 210, 192) 73.6%,
      rgb(192, 255, 255) 102.14%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.h2 {
  font-size: 18px;
  background: cyan;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(
      90deg,
      rgb(192, 255, 255) -3.12%,
      rgb(138, 247, 238) 29.71%,
      rgb(127, 210, 192) 73.6%,
      rgb(192, 255, 255) 102.14%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1024px) {
  .h2 {
    display: none;
  }
}
.text {
  font-size: 18px;
}

.YouTube {
  width: 100%;
  margin-top: 50px;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 4px rgba(88, 255, 255, 0.1);
}

.navBar {
  height: 85px;
  background-image: linear-gradient(
    rgba(24, 54, 64, 0.25),
    rgba(0, 0, 0, 0.85)
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6%;
}

.countdownHeader {
  outline: none;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(
      90deg,
      rgb(174, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(210, 71, 203) 73.6%,
      rgb(215, 103, 237) 102.14%
    ) !important;
  backdrop-filter: blur(38.5864px) !important;
  padding: 5px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  text-shadow: 1px 1px 4px black;
  display: block;
  font-weight: 600;
}

.countdownBigText {
  font-size: 20px;
  font-weight: 1000;
  margin-right: 3px;
}

.countdownSmallText {
  font-size: 12px;
  margin-right: 12px;
}

.navLink {
  color: white;
  padding: 20px;
  text-decoration: none;
}

.footer {
  border-top: 1px solid rgb(12, 32, 32);
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  margin-top: 0px;
  height: 85px;
  background-image: linear-gradient(
    rgba(24, 54, 64, 0.25),
    rgba(0, 0, 0, 0.85)
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6%;
  text-align: center;
}

.footerText {
  font-size: 16px;
  color: white;
}

body {
  background: black;
}

.countdownDescription {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
}

.countdown {
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    #336df0bb 31.07%,
    #142f70bb 86.75%
  ) !important;
  border-width: 2px;
  border-color: black;
  color: white;
  text-align: center;
  font-size: 44px;
  width: 88px;
}
.countdown-sub {
  font-size: 1rem;
  color: #aaa;
  text-align: center;
}
