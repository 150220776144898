.HeaderContainer {
    display: flex;
    width: 99%;
    height: fit-content;

    @media screen and (max-width: 900px) and (min-width: 700px) {
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
    }

    @media screen and (max-width: 1100px) and (min-width: 900px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        width: 100%;
        margin-bottom : 5px;
    }

    align-items: center;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

    .BalanceSection {

        flex-direction: row;
        display: flex;
        align-items: center;
        padding: 6px 6px;
        border-radius: 13px;
        margin-top: 0.25rem;

        @media screen and (max-width: 700px) {
            flex-direction: column;
            margin-bottom: 10px;
        }

        img {
            height: 80px;
            margin-right: 0.5rem;

            @media screen and (max-width: 640px) {
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }

        p {
            margin: 0;
            font-weight: 500;
            color: #6ac4d7;
            font-size: 32px;
            letter-spacing: 3px;
            padding-top: 0.7rem;
            padding-left: 0.5rem;
        }
    }
}

.footer {
    border-top: 1px solid #5d5d5d;
    border-bottom: 1px solid #5d5d5d;
    text-align: center;
    display: flex;
    justify-content: space-between;
    // padding: 18px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: auto;

}

.contractAddress {
    box-sizing: border-box;
    width: 12%;
    height: 40px;
    left: 30px;
    position: relative;
}

.footerContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 20px;
    color: white;
}


.dashboardText {
    @media screen and (max-width: 640px) {
        margin-bottom: 20px;
    }

    // background: radial-gradient(306.12% 3126.18% at 16.67% 33.7%, #204fb5 0%, #769c95 84.12%, #1f7bc1 100%);
    border-radius: 50px;
    font-size: 18px;
    color: #1f0909;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    height: 45px;
    width: 140px;
    line-height: 20px;

    &:last-child {
        @media screen and (max-width: 640px) {
            margin-right: 0;
        }
    }
}

.endSection {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 10px;
    color: #D9D9D9;
    width: 90%;
    margin: 0 auto;
    height: 10px;
}

.HeaderLine {
    border-bottom: 1px solid;
    // margin-bottom: 10px;
}

.HeaderTitleRouter {
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }

    text-decoration: none;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: #004beb;
}

.HeaderTextContainer {
    @media screen and (max-width: 700px) {
        margin-top: 10px;
        margin-left: 0px;
    }

    @media screen and (max-width: 350px) {
        display: flex;
        flex-direction: column;
    }

    margin-left: 40px;
}

.HeaderUnselected {
    // color: rgb(32, 37, 50);
    // font-size: 16px;
    // background: transparent;
    // padding: 7px 12px 7px 12px;
    // border: 0px solid transparent;
    // border-radius: 30px;
}

.HeaderSelected {
    // color: rgb(32, 37, 50);
    font-size: 16px;
    padding: 7px 12px 7px 12px;
    border: 1px solid transparent;
    border-radius: 30px;
}


.HeaderText{
    color: #343852;
    font-size: 18px;
}